/* Box sizing rules */
*,
*::before,
*::after {
    box-sizing: border-box;
}

/* Prevent font size inflation */
html {
    -moz-text-size-adjust: none;
    -webkit-text-size-adjust: none;
    text-size-adjust: none;
    scroll-behavior: smooth;
}

/* Remove default margin in favour of better control in authored CSS */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote {
    margin-block-end: 0;
}

/* Set core body defaults */
body {
    min-height: 100vh;
    line-height: 1.5;
}

/* Set shorter line heights on headings and interactive elements */
h1,
h2,
h3,
h4,
button {
    line-height: 1.1;
}

/* Balance text wrapping on headings */
h1,
h2,
h3,
h4 {
    text-wrap: balance;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    font-weight: 400;
}

a {
    text-decoration-skip-ink: auto;
    color: currentColor;
    cursor: pointer;
    text-decoration: none;
}

/* Make images easier to work with */
img,
picture {
    max-width: 100%;
    display: block;
}

/* Inherit fonts for inputs and buttons */
button{
    font-family: inherit;
    font-size: inherit;
}

/* Remove animations for people who've turned them off */
@media (prefers-reduced-motion: reduce) {
    *,
    *::before,
    *::after {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
}

div {
    padding: 0;
    margin: 0;
}

p {
    text-wrap: pretty;
    text-align: justify;
}
:root {
    --ff-work-sans: "Work Sans", sans-serif;
    --custom-bg-color: 242, 242, 242;
    --custom-primary-clr: 249, 139, 46;
    --custom-primary-dark-clr: 165, 89, 21;
    --custom-secondary-clr: 54, 143, 139;
    --custom-secondary-dark-clr: 39, 119, 115;
    /* --custom-secondary-clr: 7, 189, 183; */
}

.center-self {
    justify-self: center;
}
.fw-500 { font-weight: 500; }

.fw-600 { font-weight: 600; }

.no-style-list { 
    list-style: none; 
    padding: 0;
}

.loading {
    padding-top: 2rem;
    width: 5rem;
    margin: 0 auto;
}
@import 'bootstrap-icons/font/bootstrap-icons.css';
  
body {
    font-family: var(--ff-work-sans);
    background-color: rgb(var(--custom-bg-color));
    accent-color: rgb(var(--custom-secondary-clr));
}

main {
    padding: 9rem 0 2rem 0;
    min-height: 80vh;
}

@media (min-width: 374px) {
    main { padding-top: 7rem; }
}

header > * { font-weight: 600; }

.navbar { background-color: white !important; }

/* links start */
#nav-items .active span, 
.footer--links .active span{
    border-color: rgb(var(--custom-primary-clr));
    color: black;
}

#nav-items span, 
.footer--links span{
    padding-bottom: .5rem;
    border-bottom: solid .1875rem transparent;
}

#nav-items span:hover, 
.footer--links span:hover, 
.footer--creds svg:hover, 
.phone a:hover {
    color: rgb(var(--custom-primary-clr));
}

.footer--links span{
    color: rgb(89, 89, 89);
}
/* links end */
/* footer start */
a.btn.custom-btn { font-size: .875rem; }

@media (min-width: 370px) {
    a.btn.custom-btn { font-size: 1rem; }
}

@media (min-width: 419px) {
    a.btn.custom-btn { font-size: 1.1875rem; }
}

.footer {
    background-color: white;
    padding: 2rem 0 5rem 0;
    width: 100%;
}

.fixed-footer {   
    background-color: white;
    position: fixed;
    width: 100%;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    padding: 1rem 0;
}

.fixed-footer div {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
}

.footer--imgs {
    display: flex;
    flex-wrap: wrap;
    margin-right: auto;
    gap: 1rem;
}
.footer--imgs > * {
    align-self: center;
}

.footer--info {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
}

@media (min-width: 769px) {
    .footer--info {
        grid-template-columns: auto 1fr;
    }

    .footer--info > ul {
        justify-self: end;
    }
}

@media (min-width: 990px) {
    .footer--info {
        grid-template-columns: 1fr 1fr;
    }
}

.footer--info > div {
    display: grid;
    grid-template-columns: 1fr auto;
    font-weight: 600;
    gap: 1rem;
}

.footer--info > ul {
    display: grid;
    grid-template-columns: 1fr;
    font-size: .875rem;
    gap: 1rem;
}

@media (min-width: 475px) {
    .footer--info > ul {
        grid-template-columns: 1fr auto;
    }
}

.geo, 
.time, 
.phone, 
.email {
    position: relative;
    padding-left: 2rem;
}

.geo::before, 
.time::before, 
.phone::before, 
.email::before {
    display: inline-block;
    font-family: 'bootstrap-icons';
    font-size: 1rem;
    font-weight: 600;
    color: rgb(var(--custom-primary-clr));
    position: absolute;
    left: 0;
}

.geo::before { content: "\F3E7"; }

.time::before { content: "\F291"; }

.phone::before { content: "\F5B4"; }

.email::before { content: "\F32C"; }

.time {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 1rem;
}

.hours { font-weight: 300; }

.footer--creds {
    display: flex;
    justify-content: space-between;
}

.footer--creds > * {
    display: flex;
    gap: .5rem;
}

.footer--creds svg { 
    font-size: 2rem; 
    color: rgb(89, 89, 89);
}
/* footer end */

/* button start */
.custom-btn {
    background-color: rgba(var(--custom-primary-clr), 1);
    color: rgb(250, 250, 250);
    font-weight: 600;
    border-radius: 15px;
    font-size: 1.1875rem;
    border: 1px solid transparent;
}

.custom-btn:hover {
    background-color: rgba(var(--custom-primary-clr), .8);
    color: rgb(250, 250, 250);
    font-weight: 600;
    cursor: pointer;
}

.custom-btn i {
    margin-right: 1rem;
}
/* button end */

main {
    max-width: 900px;
    margin-inline: auto;
}

main > div >  *, .space > * {
    margin-bottom: 1rem;
}

main h1, main h2 {
    text-align: center;
    font-weight: 500;
    color: rgb(var(--custom-secondary-clr));
}

main .link {
    color: rgb(var(--custom-primary-dark-clr));
    font-weight: 00;
}

main .link:hover { cursor: pointer; }

.about img {
    width: 100%;
    border-radius: .5rem;
    margin-bottom: 1.5rem;
}

.btn:hover, .btn:active, .btn.active {
    color: white !important;
}

.booking--form .additional {
    width: 90%;
    margin-inline: auto;
}

.booking--form .additional label {
    margin-bottom: .5rem;
}

.booking--form .row div:first-child {
    display: flex;
}

.booking--form .row {
    margin-top: 1rem;
    column-gap: 1rem;
}

.booking--form > div > div{
    display: flex;
    justify-content: center;
}

.booking--receipt > p {
    text-align: justify;
    margin: 1rem 0;
}

.carousel-item {
    position: relative;
    width: 100%;
    padding-top: 56.25%; 
}

.carousel-item img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}  

.clean iframe {
    min-width: 335px;
    min-height: 400px;
 }

.contact {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 2rem;
}

.contact > ul {
    padding: 0;
}

.contact > ul > li > span:first-child {
    font-weight: 500;
    color: rgb(var(--custom-primary-clr));
}

.contact > ul > li {
    margin-bottom: 1rem;
}

.contact--form-submitted {
    place-self: center;
}

.error pre {
    text-align: center;
    font-size: 1rem;
    text-wrap: wrap;
}

.error h1 {
    margin-bottom: 2rem;
}

.faqs > h2 {
    margin-top: 2rem;
}

.gift-card iframe, .quote iframe{
    display: block;
    margin-inline: auto;
    overflow: hidden !important;
}

iframe {
    border-radius: .5rem;
}

.invalid {
    color: rgb(220, 52, 68);
    margin-top: .5rem;
}

.invalid-text { border-color: #dc3545; }
.invalid-text:focus { 
    border-color: #dc3545;
    box-shadow: 0 0 0 .25rem rgba(220, 53, 69, .25); 
}

.quote, .service, .contact--form {
    background-color: white;
    padding: 1rem;
    border-radius: .5rem;
}

.quote > *, .service > * {
    margin-bottom: 1rem;
}

.quote h2 {
    font-size: 1.375rem;
    text-align: start;
    color: rgb(var(--custom-secondary-dark-clr));

}

.quote select{
    width: 20ch;
}

.quote--button, .booking--button {
    display: flex;
    justify-content: center;
}

.quote--extras {
    max-width: 750px;
    justify-self: center;
}

.quote--form > p, .booking--form > p, .contact--form > p {
    text-align: center;
    color: rgb(var(--custom-secondary-dark-clr));
    margin: 1rem 0;
}

.quote--icon {
    max-width: 7rem;
    background-color: rgba(226, 230, 234, 0.27);
    padding: 1rem;
    border-radius: 25px;
    margin-inline: auto;
}

.quote--icon img {
    height: 4rem;
}

.quote--icon + p {
    text-align: center;
    margin: .5rem 0;
}

.quote--quantity {
    display: flex;
    justify-content: space-around;
}

.quote--quantity  p {
    align-self: center;
}

.quote--select {
    display: grid;
    grid-template-columns: auto;
    gap: .5rem;
}

.quote--tablist {
    display: flex;
    gap: 2rem;
    justify-content: center;
}

.quote--tablist > button {
    font-weight: 500;
    border: none;
    background-color: transparent;
    color: rgb(89, 89, 89);
    border-bottom: solid .1875rem transparent;
    padding: .75rem 0;
    margin-bottom: 1rem;
}

.quote--tablist > button:hover {
    color: rgb(var(--custom-primary-clr))
}

.quote--tablist > button[aria-selected="true"]{
    color: black;
    border-bottom-color: rgb(var(--custom-primary-clr));
}

.quote--withoutQuantity {
    place-items: center;
}

.quote--withQuantity {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 1rem;
}

.quote--withQuantity > * {
    width: 12.5rem;
    margin-bottom: 1rem;
}

.quote__480 { max-width: 480px; }

.quote__480 h2 { 
    color: rgb(var(--custom-secondary-dark-clr));
    text-align: center;
}

.receipt div {
    margin: 1rem 0 2rem 0;
}

.receipt p {
    text-align: center;
}

.receipt td {
    color: black !important;
}

.receipt div:last-child {
    display: grid;
    grid-template-columns: 1fr auto;
    column-gap: 1rem;
}

.row > * {
    margin-bottom: 1rem;
}

.privacy-policy div > p:first-of-type, .terms div > p:first-of-type  {
    font-weight: 600;
}
.privacy-policy div > p:not(:first-of-type) {
    padding-bottom: .5rem;
}

.service h2 {
    font-size: 1.1875rem;
    color: rgb(var(--custom-secondary-dark-clr));
}

.terms div > p:not(:first-of-type) {
    padding-bottom: .5rem;
    padding-left: 1rem;
}

.terms div > ul {
    padding: .5rem 0;
    padding-left: 3rem;
}

td:not(:first-of-type) {
    text-align: center;
    color: rgb(var(--custom-secondary-clr));
}

td i {
    font-size: 1.25rem;
}

.valid {
    color: rgb(24, 135, 83);
    margin-top: .5rem;
}

@media (min-width: 390px){
    .quote iframe {
        min-height: 4750px;
    }
    .quote--select{
        grid-template-columns: auto auto 1fr;
        gap: 1rem;
    }
}

@media (min-width: 410px){
    .quote iframe {
        min-height: 4650px;
    }
}

@media (max-width: 452px){
    .quote--extras > div{
        margin-bottom: 3rem;
    }
    
    .quote--extras > div:first-child{
        margin-top: 1rem;
    }
}

@media (min-width: 515px){
    .quote iframe {
        min-height: 4500px;
    }
}

@media (max-width: 576px){
    #time1, #time2, #time3 {
        margin-left: 1.75rem;
    }
}

@media (min-width: 775px){
    .about img {
        max-width: 420px;
        float: left;
        margin: 0 15px 15px 0;
    }
    .quote iframe {
        min-height: 4400px;
    }
    .booking--form .additional {
        width: 50%;
        margin-inline: auto;
    }
}

@media (min-width: 992px){
    .contact {
        grid-template-columns: auto 1fr;
    }
    .quote iframe {
        min-height: 3500px;
    }
}