@import "../../../node_modules/bootstrap/scss/functions";

$primary: rgb(7, 189, 183);

@import "../../../node_modules/bootstrap/scss/variables";

.dropdown-item:active {
    background-color: rgba(247, 127, 0, .8) !important;
}

@import "../../../node_modules/bootstrap/scss/bootstrap";
